import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { DataService } from "src/app/service/data.service";
import { LocalStorageService } from "angular-web-storage";
import { CommonFunction } from "src/app/service/common-function.service";
import { NotesService } from "src/app/service/notes.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ToastrService } from "ngx-toastr";
import { Fonts } from "src/app/util/static-data";
import { WidgetDataService } from "src/app/service/widget-data.service";
import { WidgetBgSettingComponent } from "../widget-bg-setting/widget-bg-setting.component";
import Quill from "quill";
import { timeout } from "rxjs/operators";

@Component({
  selector: "app-sticky-notes-setting",
  templateUrl: "./sticky-notes-setting.component.html",
  styleUrls: ["./sticky-notes-setting.component.scss"],
})
export class StickyNotesSettingComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild(WidgetBgSettingComponent, { static: false })
  widgetBgSettingComponent: WidgetBgSettingComponent;

  @Input() notesSettingModal: any;
  @Input() category: string;
  @Input() activeLayout: any;
  @Input() notesWidgetObject: any;

  notesBodyContent = undefined;
  widgetSettings = [];
  settingDisplayflag: any;
  activeMirrorDetails: any;
  availableFontFamilies = [...Fonts];

  //background widget setting
  widgetType: any;
  widgetBgSetting: any;
  newBgSetting: any;
  widgetLayoutDetails: any;
  oldData: any;
  editorModules: any;
  mirrorBackgroundSetting: any;
  fontFamiliesNames: string[] = [];

  readonly DEFAULT_FONT_SIZE: string = "16px";

  constructor(
    private _dataService: DataService,
    private storage: LocalStorageService,
    private toastr: ToastrService,
    private _notesService: NotesService,
    private commonFunction: CommonFunction,
    private loadingSpinner: Ng4LoadingSpinnerService,
    private widgetDataService: WidgetDataService
  ) {}

  ngOnInit() {
    const getSettingOptions = this.storage.get("activeMirrorDetails");
    this.mirrorBackgroundSetting = getSettingOptions.mirror.backgroundSetting;

    this.fontFamiliesNames = [
      ...this.availableFontFamilies.map((fontObj) => fontObj.googleFontName),
    ];

    // register fonts for quill editor
    const font = Quill.import("attributors/style/font");
    font.whitelist = this.fontFamiliesNames;
    Quill.register(font, true);

    //register font sizes for quill editor
    let size = Quill.import("attributors/style/size");
    size.whitelist = [
      "6px",
      "8px",
      "10px",
      "12px",
      "14px",
      "16px",
      "18px",
      "20px",
      "24px",
      "30px",
      "36px",
      "48px",
      "60px",
      "70px",
      "80px",
    ];
    Quill.register(size, true);

    // register alignment to have inline styles
    Quill.register(Quill.import("attributors/style/align"), true);

    // set clean icon
    let icons = Quill.import("ui/icons");
    icons[
      "clean"
    ] = `<svg fill="#000000" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <style>
              .cls-1 {
                fill: none;
              }
            </style>
          </defs>
          <path d="M29.4384,16.5715l-7.985-7.9856a2.0014,2.0014,0,0,0-2.8291,0l-5.3584,5.3584L9,2H7L2,16H4l.999-3h6l.8035,2.4077L4.5858,22.6244a2,2,0,0,0,0,2.8282L9.1316,30h9.5908l10.716-10.717A1.9173,1.9173,0,0,0,29.4384,16.5715ZM5.6653,11l2.331-7,2.3355,7Zm12.229,17H9.96L6,24.0381l6.3123-6.3115L20.24,25.6538Zm3.76-3.76-7.9275-7.9272L20.0393,10l7.9268,7.9272Z"/>
          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
        </svg>`;

    this.editorModules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ size: size.whitelist }], // custom dropdown
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: this.fontFamiliesNames }],
        [{ align: ["", "center", "right"] }],
        ["clean"],
        ["link"], // link
      ],
    };
  }

  ngOnChanges(change: any) {
    if (
      change.notesWidgetObject &&
      change.notesWidgetObject.currentValue != undefined
    ) {
      this._dataService.getWidgetSettingsLayout().subscribe((data) => {
        this.widgetLayoutDetails = data;
        this.widgetSettings = data.widgetSetting;
      });

      if (this.notesWidgetObject != undefined) {
        if (this.notesWidgetObject.data != null) {
          this.oldData = this.notesWidgetObject.data;

          this.widgetDataService.widgetFormState[
            this.category
          ].settings.initialValue =
            this.notesWidgetObject.data.notesWidgetDetail.notesData || "";
        }

        this.setBackgroundWidgetDetail();
        this.openTextEditorToAddNewNote();
        setTimeout(() => {
          this.resizeElement();
        }, 500);
      }
    }
  }

  ngAfterViewInit(): void {
    const toolbarFontOptions = document.querySelectorAll(
      ".ql-font .ql-picker-item"
    );
    toolbarFontOptions.forEach((option: any, index) => {
      option.style.fontFamily = this.fontFamiliesNames[index];
    });
  }

  resizeElement() {
    //set the height of the quill editor

    const notesElement = document.getElementsByClassName(
      "notes modal-body"
    )[0] as HTMLElement;
    var bodyHeight = notesElement.getBoundingClientRect().height;
    const quillContainer = document.getElementsByClassName(
      "ql-container"
    ) as HTMLCollectionOf<HTMLElement>;
    const quillToolbar = document.getElementsByClassName(
      "ql-toolbar"
    ) as HTMLCollectionOf<HTMLElement>;
    const height = quillToolbar[0].getBoundingClientRect().height;
    quillContainer[0].style.height = bodyHeight - (height + 138) + "px";
  }

  onEditorCreated(quill: any): void {
    quill.format("font", this.mirrorBackgroundSetting.fontFamily);
    quill.format("size", this.DEFAULT_FONT_SIZE);
  }

  setBackgroundWidgetDetail() {
    this.widgetType = this.category;
    let widgetData = this.storage.get("selectedwidget");
    if (widgetData != null) {
      this.widgetBgSetting = widgetData.widgetBackgroundSettingModel;

      this.widgetDataService.widgetFormState[
        this.category
      ].format.initialValue = {
        ...this.widgetBgSetting,
      };
    }
    this.activeMirrorDetails = this.storage.get("activeMirrorDetails");
  }

  openTextEditorToAddNewNote() {
    if (this.notesWidgetObject != null) {
      if (
        this.notesWidgetObject.data.notesWidgetDetail.notesData != undefined
      ) {
        this.notesBodyContent =
          this.notesWidgetObject.data.notesWidgetDetail.notesData;
      } else {
        this.notesBodyContent = undefined;
      }
    } else {
      this.notesBodyContent = undefined;
    }
  }

  save(): void {
    // Checks if need to save settings form
    const settingsForm =
      this.widgetDataService.widgetFormState[this.category].settings;

    if (this.notesBodyContent !== settingsForm.initialValue) {
      this.saveNotesSetting();
      settingsForm.initialValue = this.notesBodyContent;
    }

    // Checks if need to save format form
    const formatForm =
      this.widgetDataService.widgetFormState[this.category].format;

    // Update bgSettingsOptions object to latest state
    this.widgetBgSettingComponent.updateBgSettingOptionsObject();

    const isFormatFormChanged = this.widgetDataService.isFormValueChanged(
      formatForm.initialValue,
      this.widgetBgSettingComponent.bgSettingOptions
    );

    if (isFormatFormChanged) {
      this.saveBackgroundSettings(
        this.widgetBgSettingComponent.bgSettingOptions
      );
      formatForm.initialValue = this.widgetBgSettingComponent.bgSettingOptions;
    }

    this.notesSettingModal.hide();
  }

  saveNotesSetting() {
    let payload = {
      id: this.notesWidgetObject.data.notesWidgetDetail.id,
      notesData: this.notesBodyContent,
      widgetSetting: {
        id: this.notesWidgetObject.widgetSettingId,
      },
    };

    this.loadingSpinner.show();
    this._notesService.updateNotesSetting(payload).subscribe(
      (res: any) => {
        this.loadingSpinner.hide();
        this.toastr.success(res.message, "Success");
        this.widgetSettings.forEach((widgetPageData) => {
          widgetPageData.widgets.forEach((element) => {
            if (
              element.widgetSettingId === this.notesWidgetObject.widgetSettingId
            ) {
              element.data.notesWidgetDetail = res.object;
            }
          });
        });
        this.widgetLayoutDetails.widgetSetting = this.widgetSettings;
        this._dataService.setWidgetSettingsLayout(this.widgetLayoutDetails);
      },
      (err: any) => {
        this.loadingSpinner.hide();
        this.toastr.error(err.error.message, "Error");
      }
    );
  }

  saveBackgroundSettings(event) {
    this.newBgSetting = event;
    const stickyBgPayload = {
      userMirrorId: this.activeMirrorDetails.id,
      mastercategory: [this.notesWidgetObject.widgetMasterCategory],
      widgetBackgroundSettingModel: this.newBgSetting,
    };
    this.commonFunction.updateWidgetSettings(
      this.newBgSetting,
      stickyBgPayload
    );
  }

  setDisplayflag() {
    this.settingDisplayflag = true;
  }

  dismissModel() {
    if (this.notesWidgetObject.data != undefined) {
      this.notesWidgetObject.data = this.oldData;
    }
    this.notesSettingModal.hide();
  }

  dismissModal() {
    this.notesSettingModal.hide();
  }
}
