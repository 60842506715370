import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "CustomFilter",
})
export class CustomFilterPipe implements PipeTransform {
  transform(items: any, filter: any, defaultFilter: boolean): any {
    if (!filter) {
      return items;
    }

    if (!Array.isArray(items)) {
      return items;
    }

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (defaultFilter) {
        return items.filter((item) =>
          filterKeys.reduce(
            (x, keyName) =>
              (x && new RegExp(filter[keyName], "gi").test(item[keyName])) ||
              filter[keyName] == "",
            true
          )
        );
      } else {
        return items.filter((item) => {
          return filterKeys.some((keyName) => {
            return (
              new RegExp(filter[keyName], "gi").test(item[keyName]) ||
              filter[keyName] == ""
            );
          });
        });
      }
    }
  }
}

@Pipe({
  name: "mapFaIconClass",
})
export class MapFaIconClassPipe implements PipeTransform {
  transform(value: string, source: any[]): string {
    const selected = source.find(
      (align) => align.value.toLowerCase() == value.toLowerCase()
    );
    return selected ? selected.icon : "";
  }
}

@Pipe({
  name: "dynamicValue",
  pure: false,
})
export class DynamicValuePipe implements PipeTransform {
  transform(item: any, type: string, commonFontFormat: any): any {
    if (!item || !type || !commonFontFormat) {
      return "";
    }

    const prefix = item.prefix;
    const defaultValues = {
      fontFamily: "default",
      fontSize: "16",
      alignment: "left",
      fontColor: "#000000",
    };

    const format = commonFontFormat[prefix] || defaultValues;

    switch (type) {
      case "fontFamily":
        return format.fontFamily;
      case "fontSize":
        return format.fontSize;
      case "alignment":
        return format.alignment;
      case "fontColor":
        return format.fontColor;
      default:
        return "";
    }
  }
}
